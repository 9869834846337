import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../components/utils/layout";
import { RebassCard } from "../utils/styled";
import { Tiles, Flex } from 'rebass';
import { ThemeProvider } from "styled-components";
import theme from "../styles/theme";
import { Link } from "gatsby";
import SEO from "../components/utils/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Termos & condições" mdxType="SEO" />
    <img style={{
      height: 300,
      marginBottom: 100
    }} src={require(`../images/orange/undraw_terms_lso0.svg`)} alt={"What_image"} />
    <h1>{`Termos & condições:`}</h1>
    <br />
    <h3>{`Primeiro, pequena contextualização:`}</h3>
    <p>{`O meu nome é Alexandre Ramalho, e sou um engenheiro informático fullstack a trabalhar atualmente na
`}<a parentName="p" {...{
        "href": "https://vwds.pt"
      }}>{`Volkswagen Digital Solutions`}</a>{`.`}</p>
    <p>{`Gosto MUITO de aprender, e por isso estou sempre à procura do meu próximo desafio.
Atualmente estou a fazer uma `}<a parentName="p" {...{
        "href": "https://radialcor.pt"
      }}>{`loja online`}</a>{` e estou a usar Django e
Wagtail, porque queria ficar mais fluente em Python, e Django é uma framework web excelente. Também queria
aprender mais sobre Jamstack, então decidi usar `}<a parentName="p" {...{
        "href": "https://gatsbyjs.org"
      }}>{`Gatsby`}</a>{` para fazer este website.
Pronto, agora já sabem um bocadinho mais sobre mim. Podem sempre dar uma espreitadela no meu
`}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/alexandre-francisco-ramalho/"
      }}>{`LinkedIn`}</a>{` para terem uma ideia mais formal sobre a minha
experiência profissional e educação.`}</p>
    <p>{`Agora a parte que interessa, `}<strong parentName="p">{`em que é que eu te posso ajudar?`}</strong></p>
    <p>{`Se tens um projeto que querias desenvolver que envolva uma componente informática ou web, eu posso ajudar!`}<br />{`
Não vejo bem isto como um "negócio" entre duas partes. Vejo mais como: Tu estás à procura de
alguém para desenvolver os teus projetos, e eu estou à procura de projetos para desenvolver.`}<br />{` Como podes cancelar já depois
de teres "um cheirinho" de como a coisa vai ficar, tens alguma coisa a perder? Também estou disponível para fazer parcerias
com outros developers, claro.
Neste momento estou a aprender mais sobre Jamstack e Machine Learning, sendo o meu proximo objetivo Programação funcional,
então, projetos que me possibilitem o uso destas tecnologias, serão especialmente atraentes para mim (estarei disponível
a fazer ainda mais barato).`}</p>
    <p>{`Isto tudo acontece da seguinte maneira:`}</p>
    <Flex flexWrap='wrap' mdxType="Flex">
    <RebassCard variant icon={1} mdxType="RebassCard">
    Entras em contacto comigo, através do
    <Link to="/#go" mdxType="Link"><i>formulário de contacto </i></Link>
    ou pelo
    <a target="_blank" href="https://www.linkedin.com/in/alexandre-francisco-ramalho/"> <i>LinkedIn</i></a> com a <i>sugestão </i>
    de um projeto.
    </RebassCard>
    <RebassCard variant icon={2} mdxType="RebassCard">
    Eu avalio se é fazível, e o meu interesse nele.
    </RebassCard>
    <RebassCard variant icon={3} mdxType="RebassCard">
    Contacto-te de volta para dizer ou que não estou interessado no projeto, ou que estou pronto para avançar!
    </RebassCard>
    <RebassCard variant icon={4} mdxType="RebassCard">
    Marcamos então uma reunião para discutir preços, requisitos e uma pequena estimativa para a duração do projeto.
    </RebassCard>
    <RebassCard variant icon={5} mdxType="RebassCard">
    Quando tiver um protótipo pronto, tu avalias-o, e:
        <br />👎 Cancelas o avanço do projeto (sem qualquer compromisso ou pagamento)
        <br />👍 Confirmas o teu interesse e pagas 50% do preço previamente discutido.
    </RebassCard>
    <RebassCard variant icon={6} mdxType="RebassCard">
    Trabalho mais no projeto.
    </RebassCard>
    <RebassCard variant icon={7} mdxType="RebassCard">
    Entrego e pagas os 50% finals.
    </RebassCard>
    <RebassCard variant icon={8} mdxType="RebassCard">
    😃 Tu ficas contente
    </RebassCard>
    <RebassCard variant icon={9} mdxType="RebassCard">
    Eu fico contente 😃
    </RebassCard>
    </Flex>
    <br />
Espero que estejas convencido, afinal, o que é que tens a perder? :)
Se ficaste com alguma dúvida, está à vontade para me mandar mensagem em qualquer das minhas redes sociais
(preferencialmente LinkedIn) ou formulário de contacto.
    <br /><br />
    <Link to="/pt/#go" mdxType="Link">Voltar para a página principal</Link>
    <br /><br />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      